import React, { Component, Fragment } from 'react';
import { connect } from 'dva';
import { Button, Icon, Layout, Menu, Select } from 'antd';

import className from 'classnames';
import intl from 'react-intl-universal';

import TopBtn from '../../components/TopBtn';
import GlobalHeader from '../../components/GlobalHeader';
import StepContent from './step.js';

import styles from './Index.less';

import logo from 'assets/svg/logo.svg';
import home from '../../assets/guidance/home.webp';
import myproduct from '../../assets/guidance/myproduct.webp';
import setting from '../../assets/guidance/setting.webp';
import order from '../../assets/guidance/order.webp';
import productItem from '../../assets/guidance/productItem.webp';
import orderPlace from '../../assets/guidance/orderPlace.webp';
import shopify_auto_fulfill from '../../assets/guidance/dsersPro/shopify_auto_fulfill.webp';

import step6_1 from '../../assets/guidance/dsersPro/6_1.png';
import step6_2 from '../../assets/guidance/dsersPro/6_2.png';
import step6_3 from '../../assets/guidance/dsersPro/6_3.png';
import step6_4 from '../../assets/guidance/dsersPro/6_4.png';
import step7_1 from '../../assets/guidance/dsersPro/7_1.png';
import step7_2 from '../../assets/guidance/dsersPro/7_2.png';

const { Header } = Layout;
const Option = Select.Option;

@connect(({ guidance, loading, login }) => ({
  guidance,
  userInfo: login.userInfo,
  loading: loading.models.guidance,
  storeLoading: loading.effects['guidance/postGuidance']
}))
export default class Guidance extends Component {
  initCheck = false; // 保证只执行一次

  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      bigStep: 0,
      status: 1,
      smallStep: [0, 0, 0, 0, 0, 0]
    };

    this.guidanceAgain = localStorage.getItem('guidanceAgain');
  }

  componentDidMount() {
    const talkfb = document.getElementById('fb-root');
    if (talkfb) {
      talkfb.style.display = 'none';
    }

    this.updateDataLayer1(this.state.bigStep);

    const { userInfo } = this.props;

    if (userInfo && !this.initCheck) {
      this.initCheck = true;
      this.fixStatus(this.props.userInfo.status);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { userInfo } = this.props;
    const { bigStep, step } = this.state;

    if (bigStep && bigStep !== prevState.bigStep) {
      this.updateDataLayer1(this.state.bigStep);
    }

    if (step && step !== prevState.step) {
      this.updateDataLayer2(this.state.step);
    }

    if (userInfo && userInfo !== prevProps.userInfo && !this.initCheck) {
      this.initCheck = true;
      this.fixStatus(this.props.userInfo.status);
    }
  }

  componentWillUnmount() {
    localStorage.removeItem('guidanceAgain');
    const talkfb = document.getElementById('fb-root');
    if (talkfb) {
      talkfb.style.display = 'block';
    }
  }

  fixStatus = status => {
    const s = `${status}`;

    if (s.length === 8) {
      return;
    }

    this.props.dispatch({
      type: 'guidance/postGuidance',
      payload: {
        data: {
          status: 11000000
        },
        callback: () => {
          if (!this.guidanceAgain) {
            window.location.href = '/app/'; // 首页
          }
        }
      }
    });
  };

  guidanceAgain = null;

  // 引导完成，改变用户状态
  postNoviceGuidance = () => {
    const { status } = this.props.userInfo;
    localStorage.removeItem('guidanceAgain');
    localStorage.setItem('tutorialOverModal', 1);

    if (this.guidanceAgain) {
      window.location.href = '/app/';
      return;
    }

    if (`${status}`.length !== 8) {
      this.props.dispatch({
        type: 'guidance/postGuidance',
        payload: {
          data: {
            status: 11000000
          },
          callback: () => {
            window.location.href = '/app/'; // 首页
          }
        }
      });
    } else {
      this.props.dispatch({
        type: 'guidance/postGuidance',
        payload: {
          data: {
            status: this.setUserStatus(status)
          },
          callback: () => {
            window.location.href = '/app/'; // 首页
          }
        }
      });
    }
  };

  setUserStatus = status => {
    const statusArr = `${status}`.split('').map(s => (s >= 1 ? '1' : '0'));

    statusArr.forEach((i, index) => {
      if (index === 1) {
        statusArr[index] = '1';
      }
    });

    return Number(statusArr.join(''));
  };

  next = () => {
    const { step } = this.state;
    if (step == 4) {
      this.nextBigStep();
      return;
    }
    this.setState({ step: this.state.step + 1 });
  };

  prev = () => {
    const n = this.state.step - 1;
    if (n < 0) {
      this.prevBigStep();
      return;
    }
    this.setState({ step: n });
  };

  smallStep = flag => {
    const { step, smallStep } = this.state;
    const arr = [...smallStep];
    const n = flag == '+' ? arr[step] + 1 : arr[step] - 1;

    arr[step] = n;

    this.setState({
      smallStep: arr
    });
  };

  // 点击i got it
  changeRadio = e => {
    const { step, nextIsDisabled } = this.state;
    nextIsDisabled[step] = false;
    this.setState({
      nextIsDisabled: [...nextIsDisabled]
    });
    this.forceUpdate();
  };

  steps = [
    {
      title: 'step1',
      content: this.step1,
      nextflag: true,
      selectedKeys: '1',
      bg: home,
      width: 932
    },
    {
      title: 'step2',
      content: this.step2,
      nextflag: true,
      selectedKeys: '1',
      bg: home,
      width: 932
    },
    {
      title: 'step3',
      content: this.step3,
      nextflag: true,
      selectedKeys: '2',
      bg: myproduct,
      width: 1040
    },
    {
      title: 'step4',
      content: this.step4,
      nextflag: true,
      selectedKeys: '6',
      bg: setting,
      width: 940
    },
    {
      title: 'step5',
      content: this.step5,
      nextflag: true,
      selectedKeys: '3',
      bg: order,
      width: 1040
    }
  ];

  // 下一大步
  nextBigStep = () => {
    this.setState({
      bigStep: this.state.bigStep + 1
    });
  };

  prevBigStep = () => {
    this.setState({
      bigStep: this.state.bigStep - 1
    });
  };

  prevAtAfterOrder = () => {
    this.setState({
      bigStep: 2
    });
  };

  updateDataLayer1 = status => {
    let stage = '';
    switch (status) {
      case 0:
        stage = 'startTutorial';
        break;
      case 1:
        stage = 'beforeStarting';
        break;
      case 2:
        stage = 'step1';
        break;
      case 3:
        stage = 'autoSync';
        break;
      case 4:
        stage = 'doNot';
        break;
      case 5:
        stage = 'getEbook';
        break;
      default:
        break;
    }

    window.dataLayer.push({
      event: 'registerCustomEvent',
      type: 'guidance',
      payload: stage
    });
  };

  updateDataLayer2 = status => {
    let stage = '';
    switch (status) {
      case 1:
        stage = 'step2';
        break;
      case 2:
        stage = 'step3';
        break;
      case 3:
        stage = 'step4';
        break;
      case 4:
        stage = 'readyToGo';
        break;
      default:
        break;
    }

    window.dataLayer.push({
      event: 'registerCustomEvent',
      type: 'guidance',
      payload: stage
    });
  };

  render() {
    const { storeLoading } = this.props;
    const { step, bigStep, status, smallStep } = this.state;

    return (
      <>
        {status == 0 && !localStorage.getItem('guidanceAgain') ? (
          <div className={styles.steploading} />
        ) : null}

        {(bigStep == 0 && status == 1) ||
        (localStorage.getItem('guidanceAgain') && bigStep == 0) ? (
          <div className={styles.welcomeToDsers}>
            <h2>{intl.get('guidance.welcome')}</h2>
            <p>{intl.getHTML('guidance.need_steps')}</p>
            <div>
              <Button type="primary" onClick={this.nextBigStep}>
                {intl.getHTML('guidance.start_tutorial')}
              </Button>
            </div>
          </div>
        ) : null}

        {bigStep == 1 ? (
          <div className={styles.shopifySetting}>
            <p className={styles.desc}>
              {intl.getHTML('guidance.before_starting')}
              <img src={shopify_auto_fulfill} />
            </p>
            <div className={styles.PrevAndNext}>
              <Button type="primary" onClick={this.prevBigStep}>
                {intl.getHTML('guidance.previous')}
              </Button>
              <Button type="primary" onClick={this.nextBigStep}>
                {intl.getHTML('guidance.next')}
              </Button>
            </div>
          </div>
        ) : null}

        {bigStep == 3 ? (
          <div className={styles.shopifySetting}>
            <div>
              <p className={styles.desc}>
                {intl.getHTML('guidance.after.dec')}
              </p>
              <ol>
                <li>{intl.getHTML('guidance.after.p1')}</li>
                <li>{intl.getHTML('guidance.after.p2')}</li>
                <li>{intl.getHTML('guidance.after.p3')}</li>
              </ol>
              <div className={styles.PrevAndNext}>
                <Button type="primary" onClick={this.prevBigStep}>
                  {intl.getHTML('guidance.previous')}
                </Button>
                <Button type="primary" onClick={this.nextBigStep}>
                  {intl.getHTML('guidance.next')}
                </Button>
              </div>
            </div>
          </div>
        ) : null}

        {bigStep == 4 ? (
          <div className={styles.afterOrdering}>
            <div className={styles.afterOrderingContent}>
              <h6>{intl.getHTML('guidance.actions.title')}</h6>
              <dl>
                <dt>{intl.getHTML('guidance.actions.do_not2')}</dt>
                <dd>
                  <div className={styles.pleaseDontImgs}>
                    <img src={step6_3} style={{ height: 210 }} />
                  </div>
                </dd>
                <dt>{intl.getHTML('guidance.actions.do_not3')}</dt>
                <dd>
                  <div className={styles.pleaseDontImgs}>
                    <img src={step6_4} style={{ height: 201 }} />
                  </div>
                </dd>
                <dt>{intl.getHTML('guidance.actions.do_not1')}</dt>
                <dd>
                  <div className={styles.pleaseDontImgs}>
                    <img src={step6_1} />
                    <img src={step6_2} />
                  </div>
                </dd>
              </dl>
              <div className={styles.PrevAndNext}>
                <Button type="primary" onClick={this.prevBigStep}>
                  {intl.getHTML('guidance.previous')}
                </Button>
                <Button type="primary" onClick={this.nextBigStep}>
                  {intl.getHTML('guidance.next')}
                </Button>
              </div>
            </div>
          </div>
        ) : null}

        {bigStep == 5 ? (
          <div
            className={className(styles.welcomeToDsers, {
              [styles.getEbook]: true
            })}
          >
            <h2>{intl.getHTML('guidance.confused')}</h2>
            <div className={styles.getEbookDetail}>
              <div>
                <p>{intl.getHTML('guidance.get_ebook')}</p>
                <img src={step7_1} />
              </div>
              <div>
                <p className={styles.checkTutorial}>
                  {intl.getHTML('guidance.check_again')}
                </p>
                <img src={step7_2} />
              </div>
            </div>
            <div>
              <Button size="large" type="primary" onClick={this.prevBigStep}>
                {intl.getHTML('guidance.previous')}
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={this.postNoviceGuidance}
                loading={storeLoading}
              >
                {intl.getHTML('guidance.next')}
              </Button>
            </div>
          </div>
        ) : null}

        <div className={styles.basicLayout}>
          <div className={styles.guidanceSider}>
            <div
              className={styles.logo}
              key="logo"
              style={{ textAlign: 'left', paddingLeft: '22px' }}
            >
              <img src={logo} alt="logo" width="117" />
            </div>
            <Menu
              selectedKeys={[this.steps[step].selectedKeys]}
              mode="inline"
              theme="dark"
              className={styles.menuUl}
              style={{ height: 'calc(100vh - 64px)' }}
            >
              <Menu.Item key="1">
                <Icon type="home" />
                <span>Home</span>
              </Menu.Item>
              <Menu.Item key="2">
                <Icon type="gift" />
                <span>My Products</span>
              </Menu.Item>
              <Menu.Item key="3">
                <Icon type="file-done" />
                <span>Orders</span>
              </Menu.Item>
              <Menu.Item key="4">
                <Icon type="shopping" />
                <span>New Feature</span>
              </Menu.Item>
              <Menu.Item key="5">
                <Icon type="bell" />
                <span>Notification</span>
              </Menu.Item>
              <Menu.Item key="6">
                <Icon type="setting" />
                <span>Setting</span>
              </Menu.Item>
              <Menu.Item key="7">
                <Icon type="question-circle" />
                <span>BLOG</span>
              </Menu.Item>
            </Menu>
          </div>

          <Layout className={styles.rightLayout}>
            <div className={styles.top_but}>
              <TopBtn />
              <Select style={{ width: 180, marginLeft: 30 }} value="all">
                <Option value="all" key="all">
                  All stores
                </Option>
              </Select>
            </div>
            <Header className={styles.fixedHeader}>
              <GlobalHeader />
            </Header>
            <div
              className={styles.content}
              style={{
                width: this.steps[step].width,
                backgroundImage: `url(${this.steps[step].bg})`,
                height: 'calc(100vh - 64px)'
              }}
            />
          </Layout>
          <div className={styles.bgShadow} />
          <div className={styles.bg}>
            {step == 0 ? (
              <div className={styles.linkStores}>
                <span>+</span>&nbsp;&nbsp;
                {intl.get('guidance.link_to_more_stores')}
              </div>
            ) : null}
            {step == 1 ? (
              <div className={styles.linkAli}>
                <span>+</span>&nbsp;&nbsp;{intl.get('guidance.link_to_ae')}
              </div>
            ) : null}
            {step == 0 || step == 1 ? (
              <Fragment>
                <div
                  className={className(styles.settingMenu, {
                    [styles.homemenu]: true
                  })}
                >
                  <Icon type="home" />
                  <span>Home</span>
                </div>
              </Fragment>
            ) : null}
            {step == 2 ? (
              <Fragment>
                <div
                  className={className(styles.settingMenu, {
                    [styles.productmenu]: true
                  })}
                >
                  <Icon type="gift" />
                  <span>My Products</span>
                </div>
                <img src={productItem} className={styles.productItem} />
              </Fragment>
            ) : null}
            {step == 3 ? (
              <Fragment>
                <div className={styles.settingMenu}>
                  <Icon type="setting" />
                  <span>Setting</span>
                </div>
                <div className={styles.shippingMenu}>Shipping</div>
              </Fragment>
            ) : null}
            {step == 4 ? (
              <Fragment>
                <div
                  className={className(styles.settingMenu, {
                    [styles.ordersmenu]: true
                  })}
                >
                  <Icon type="file-done" />
                  <span>Orders</span>
                </div>
                <img src={orderPlace} className={styles.orderPlace} />
              </Fragment>
            ) : null}

            <StepContent
              step={step}
              smallStep={smallStep}
              next={this.next}
              prev={this.prev}
            />
          </div>
        </div>
      </>
    );
  }
}
