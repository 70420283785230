import React from 'react';
import { Button, Icon, Popover } from 'antd';

import className from 'classnames';
import intl from 'react-intl-universal';

import styles from './Index.less';

import step1_2 from '../../assets/guidance/dsersPro/1_2.png';
import step2_2 from '../../assets/guidance/dsersPro/2_2.png';
import step2_3 from '../../assets/guidance/dsersPro/2_3.png';
import step3 from '../../assets/guidance/dsersPro/3.png';
import step4_1 from '../../assets/guidance/dsersPro/4_1.jpg';
import step4_2 from '../../assets/guidance/dsersPro/4_2.jpg';
import step5_1 from '../../assets/guidance/dsersPro/5_1.jpg';
import step5_2 from '../../assets/guidance/dsersPro/5_2.jpg';

export default function StepContent(props) {
  const { step, smallStep, next, prev } = props;
  const arr = [
    [
      <div className={className(styles.step, styles.step1)} key="k1_1">
        <div className={styles.content}>
          <h3>
            {intl.getHTML('guidance.step1.title')}{' '}
            <a
              className={styles.foreignLink}
              href="https://www.blog.dserspro.com/post/how-to-set-up-your-dsers-pro-account"
              target="_blank"
            />
          </h3>
          <div className={styles.stepAndImg}>
            <div className={styles.stepStep}>
              <h6>{intl.getHTML('guidance.step1.step1_dec')}</h6>
            </div>
            <div className={styles.linkBtn}>
              <Icon type="plus-circle" theme="filled" />{' '}
              {intl.get('guidance.link_to_more_stores')}
            </div>
          </div>
          <div className={className(styles.stepArrow, styles.step1Arow)} />
        </div>
        <div className={styles.content}>
          <h3>{intl.get('guidance.step1.step2_dec')}</h3>
          <div className={styles.stepAndImg}>
            <Popover
              trigger="hover"
              content={<img className={styles.bigimg} src={step1_2} />}
              placement="top"
            >
              <img
                src={step1_2}
                style={{ width: 380 }}
                className={styles.stepImgDown}
              />
            </Popover>
          </div>
          <div className={className(styles.stepArrow, styles.step1TipArow)} />
          <div className={styles.PrevAndNext}>
            <Button type="primary" onClick={prev}>
              {intl.get('guidance.previous')}
            </Button>
            <Button type="primary" onClick={next}>
              {intl.get('guidance.next')}
            </Button>
          </div>
        </div>
      </div>
    ],
    [
      <div className={className(styles.step, styles.step1)} key="k2_1">
        <div className={styles.content}>
          <h3>
            {intl.get('guidance.step2.title')}{' '}
            <a
              className={styles.foreignLink}
              href="https://www.blog.dsers.com/post/how-to-set-up-your-dsers-account"
              target="_blank"
            />
          </h3>
          <div className={styles.stepAndImg}>
            <div className={styles.stepStep}>
              <h6> {intl.getHTML('guidance.step2.step1_dec')} </h6>
            </div>
            <div className={styles.linkBtn}>
              <Icon type="plus-circle" theme="filled" />{' '}
              {intl.get('guidance.link_to_ae')}
            </div>
          </div>
          <div className={className(styles.stepArrow, styles.step2Arow)} />
          <div className={styles.PrevAndNext}>
            <Button type="primary" onClick={prev}>
              {intl.get('guidance.previous')}
            </Button>
            <Button type="primary" onClick={next}>
              {intl.get('guidance.next')}
            </Button>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.stepAndImg}>
            <Popover
              trigger="hover"
              content={<img className={styles.bigimg} src={step2_2} />}
              placement="top"
            >
              <img
                src={step2_2}
                style={{ width: 200, marginBottom: 20 }}
                className={styles.stepImgDown}
              />
            </Popover>
          </div>
          <h3>{intl.getHTML('guidance.step2.step3_dec')}</h3>
          <div className={styles.stepAndImg}>
            <Popover
              trigger="hover"
              content={<img className={styles.bigimg} src={step2_3} />}
              placement="top"
            >
              <img
                src={step2_3}
                style={{ width: 200 }}
                className={styles.stepImgDown}
              />
            </Popover>
          </div>
          <div className={className(styles.stepArrow, styles.step1TipArow)} />
        </div>
      </div>
    ],
    [
      <div
        className={className(styles.step, { [styles.stepProduct]: true })}
        key="k3_1"
      >
        <div className={styles.content}>
          <h3>{intl.getHTML('guidance.step3.title')}</h3>
          <div className={styles.stepAndImg}>
            <div className={styles.stepStep}>
              <h6>{intl.getHTML('guidance.step3.dec')}</h6>
            </div>
            <img src={step3} className={styles.stepImgDown} />
          </div>
          <div className={className(styles.stepArrow, styles.step3Arow)} />
        </div>
        <div className={styles.content}>
          <h3>{intl.getHTML('guidance.step3.map_multiple')}</h3>
          <dl className={styles.mappingTip}>
            <dt>{intl.getHTML('guidance.step3.map_from_to')}</dt>
            <dd>
              {intl.getHTML('guidance.step3.advance', {
                foreignLink: styles.foreignLink
              })}
            </dd>
            <dt>{intl.get('guidance.step3.upsell_promotion')}</dt>
            <dd>
              {intl.getHTML('guidance.step3.bogo_bundle', {
                foreignLink: styles.foreignLink
              })}
            </dd>
          </dl>
          <div className={className(styles.stepArrow, styles.step1TipArow)} />
          <div className={styles.PrevAndNext}>
            <Button type="primary" onClick={prev}>
              {intl.get('guidance.previous')}
            </Button>
            <Button type="primary" onClick={next}>
              {intl.get('guidance.next')}
            </Button>
          </div>
        </div>
      </div>
    ],

    [
      <div className={className(styles.step, styles.stepSetting)} key="k4_1">
        <div className={styles.content}>
          <h3>{intl.get('guidance.step4.title')}</h3>
          <div className={styles.stepAndImg}>
            <div className={styles.stepStep}>
              <h6>
                {intl.getHTML('guidance.step4.dec', {
                  foreignLink: styles.foreignLink
                })}
              </h6>
            </div>
            <div className={styles.stepImg}>
              <Popover
                trigger="hover"
                content={<img className={styles.bigimg} src={step4_1} />}
                placement="left"
              >
                <img src={step4_1} />
              </Popover>
              <Popover
                trigger="hover"
                content={<img className={styles.bigimg} src={step4_2} />}
                placement="left"
              >
                <img src={step4_2} style={{ width: 192 }} />
              </Popover>
            </div>
          </div>
          <div className={className(styles.stepArrow, styles.step4Arow)} />
          <div
            className={className(styles.stepArrow, styles.step4SettingArow)}
          />
          <div
            className={className(styles.stepArrow, styles.step4ShippingArow)}
          />
          <div className={styles.PrevAndNext}>
            <Button type="primary" onClick={prev}>
              {intl.get('guidance.previous')}
            </Button>
            <Button type="primary" onClick={next}>
              {intl.get('guidance.next')}
            </Button>
          </div>
        </div>
      </div>
    ],

    [
      <div className={className(styles.step, styles.stepOrder)} key="k5_1">
        <div className={styles.content}>
          <h3>
            {intl.get('guidance.step5.title')}{' '}
            <a
              className={styles.foreignLink}
              href="https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/"
              target="_blank"
            />
          </h3>
          <div className={styles.stepAndImg}>
            <div className={styles.stepStep}>
              <h6>{intl.get('guidance.step5.dec')}</h6>
            </div>
            <img
              src={step5_1}
              className={styles.stepImgDown}
              style={{ width: 300, marginBottom: 30 }}
            />
          </div>
          <div className={styles.stepAndImg}>
            <div className={styles.stepStep}>
              <h6>{intl.get('guidance.step5.tip')}</h6>
            </div>
            <Popover
              trigger="hover"
              content={<img className={styles.bigimg} src={step5_2} />}
              placement="top"
            >
              <img
                src={step5_2}
                className={styles.stepImgDown}
                style={{ width: 300 }}
              />
            </Popover>
          </div>
          <div className={className(styles.stepArrow, styles.step5Ready)} />
          <div className={styles.PrevAndNext}>
            <Button type="primary" onClick={prev}>
              {intl.get('guidance.previous')}
            </Button>
            <Button type="primary" onClick={next}>
              {intl.get('guidance.next')}
            </Button>
          </div>
        </div>
      </div>
    ]
  ];
  return arr[step][smallStep[step]];
}
